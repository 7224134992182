<div class="flex flex-row flex-nowrap relative items-center">
  <button class="text-e-mid-green bg-e-dark-green size-[32px] md:size-[40px] rounded-full absolute left-0" (click)="swipeLeft()">
    <i class="bi bi-chevron-left"></i>
  </button>
  <div class="bg-e-light-green flex flex-row rounded-md pt-3 px-[20px] md:px-[35px] mx-4 overflow-hidden">
    <div #lifestylelist class="grid grid-flow-col grid-cols-auto flex-nowrap overflow-x-scroll scrollbar-hide">
      <div class="lifestyle flex flex-col px-3 cursor-pointer" *ngFor="let style of lifestyles" (click)="changeLifestyle(style.id)">
        <img class="h-[31px] mx-auto" alt="logo {{style.name}}" src="./assets/images/icons/lifestyle/icon_leefstijl_{{style.id}}.svg" />
        <span class="text-sm mx-auto text-nowrap capitalize" [ngClass]="{'font-bold': lifestyleid === style.id}">{{style.name}}</span>
        <div class=" rounded-full w-full h-[4px] mt-2" [ngClass]="{'bg-e-orange': lifestyleid === style.id, 'bg-transparant': lifestyleid !== style.id}"></div>
      </div>
    </div>
  </div>
  <button class="text-e-mid-green bg-e-dark-green size-[32px] md:size-[40px] rounded-full absolute right-0" (click)="swipeRight()">
    <i class="bi bi-chevron-right"></i>
  </button>
</div>
