import { Component, Input, Inject, ViewEncapsulation, PLATFORM_ID, OnChanges } from '@angular/core';
import { isPlatformBrowser, NgIf, NgFor, NgClass } from '@angular/common';

import { WINDOW } from '../../_service/WindowService';
import { CmsSectionComponent } from '../../sharedModule/cms-section/cms-section.component';
import { CmsDirective } from '../cms.directive';
import { CmsPopupComponent } from 'src/sharedModule/cms-popup/cms-popup.component';
// import { DomSanitizer } from '@angular/platform-browser';
// import { TimeInterval } from 'rxjs';

@Component({
    selector: 'app-cms-page',
    templateUrl: './cmspage.component.html',
    styleUrls: ['./cmspage.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, CmsDirective, NgFor, NgClass, CmsSectionComponent, CmsPopupComponent]
})
export class CMSpageComponent implements OnChanges {
  @Input() story: any;
  content: any; // the real content of the page or the story we may process it first

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(WINDOW) private window: Window,
  ) {

    // const cms = window.location.hostname.startsWith('prismic-') ? "PRISMIC" : window.location.hostname.startsWith('sb-') ? 'SB' : 'NATIVE';

    if (isPlatformBrowser(this.platformId)) {
      if (!!this.window['StoryblokBridge']) {
        const StoryblokBridge = this.window['StoryblokBridge'];

        const storyblokInstance = new StoryblokBridge(); // TODO should we move this to the service ?

        storyblokInstance.on(['published', 'change'], (event) => {
          // console.log(event);
          // reload page if save or publish is clicked
          // window.location.reload();
        });
        storyblokInstance.on('input', (event) => {
          // Access currently changed but not yet saved content via: 
          this.content = event.story.content;
        });
        storyblokInstance.on('enterEditmode', (event) => {
          // console.log(event);
        });
      }
    }
    // this.isPrerendered = this._globalService.isPrerendered;

  }

  ngOnChanges() {
    // console.log('changes');
    if (!!this.story) {
      this.content = this.story.content;
    }
  }

  get_content(field) {
    if (!field || field.length === 0) return null;
    // abstract field render can be used for content from different (CMS) sources
    if (!!field.text) return field.text;
    // no extra work need
    return field;
  }
}

