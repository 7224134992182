import { Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild, input } from '@angular/core';
import { LifestyleService } from '../../_service/LifestyleService';
import { Lifestyle } from '../../_model/recipe';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { WINDOW } from '../../_service/WindowService';

@Component({
  selector: 'app-lifestyleselection',
  standalone: true,
  imports: [NgFor, NgIf, NgClass],
  templateUrl: './lifestyleselection.component.html',
  styleUrl: './lifestyleselection.component.scss'
})
export class LifestyleselectionComponent {

  @Input() lifestyleid: number;
  @Output() lifestyleidChange: EventEmitter<number> = new EventEmitter<number>();

  lifestyles: Lifestyle[];
  scrollPosition = 0;
  scrollEnd = false;

  @ViewChild('lifestylelist') lifestylelistEl: ElementRef;

  constructor(
    private _lifestyleService: LifestyleService,
    @Inject(WINDOW) private window: Window,
  ) {
    this._lifestyleService.getLifestyleList().subscribe(res => { this.lifestyles = res.filter(l => l.enabled) })
    this.window.addEventListener('scroll', this.onScroll, true);
  }

  ngOnDestroy() {
    this.window.removeEventListener('scroll', this.onScroll, true);
  }

  onScroll = (event: any): void => {
    this.scrollPosition = this.lifestylelistEl.nativeElement.scrollLeft;
    if (this.lifestylelistEl.nativeElement.offsetWidth + this.lifestylelistEl.nativeElement.scrollLeft >= this.lifestylelistEl.nativeElement.scrollWidth) {
      this.scrollEnd = true;
    } else {
      this.scrollEnd = false;
    }
  }


  changeLifestyle(lifestyleid: number) {
    this.lifestyleid = lifestyleid;
    this.lifestyleidChange.emit(lifestyleid);
  }


  swipeLeft() {
    this.lifestylelistEl.nativeElement.scrollTo({ left: this.scrollPosition -= 200, top: 0, behavior: 'smooth' });
  }

  swipeRight() {
    this.lifestylelistEl.nativeElement.scrollTo({ left: this.scrollPosition += 200, top: 0, behavior: 'smooth' });
  }

}
