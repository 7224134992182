<!-- will render pages bases on stories or pages -->
<ng-container *ngIf="!!content">

  <div [appStoryblok]="get_content(content._editable)" class="bg-white">

    <ng-container *ngFor="let section of content.sections; let i = index;">
      <ng-container *ngIf="section.component ==='popup'; else normalsections">
        <app-cms-popup [popup]="section"></app-cms-popup>
      </ng-container>
      <ng-template #normalsections>
        <section [appStoryblok]="get_content(section._editable)" [ngClass]="{'mt-[-10px]' : i === 0}" [id]="!section.anchor ? section._uid : section.anchor">
          <app-cms-section [section]="section" [index]="i"></app-cms-section>
        </section>
      </ng-template>
    </ng-container>
  </div>
</ng-container>